<template>
<!-- Firm.vue -->
<div class="firm-box">
  <el-tabs v-model="activeName" v-loading="loading">
    <el-tab-pane label="基本信息" name="first">
        <!-- baseinfo -->
        <el-form ref="form" :model="firm" label-width="80px">
            <el-form-item label="企业名称">
                <el-input v-model="firm.name" :disabled="!editInfo" prefix-icon="el-icon-user"
                 placeholder="企业名称"></el-input>
            </el-form-item>
            <el-form-item label="企业简称">
                <el-input v-model="firm.shortname" :disabled="!editInfo"  prefix-icon="el-icon-user"
                placeholder="企业简称"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input v-model="firm.tel" :disabled="!editInfo"  prefix-icon="el-icon-phone"
                placeholder="输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="web_hook">
                <el-input v-model="firm.webhook" :disabled="!editInfo"  prefix-icon="el-icon-help"
                 placeholder="webhook是企业微信群机器人地址"></el-input>
            </el-form-item>
            <el-form-item label="联系地址">
                <el-input type="textarea" :disabled="!editInfo"  prefix-icon="el-icon-address" maxlength="100" show-word-limit
                v-model="firm.address" placeholder="在100字符以内"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="margin-left: 10px;" size="small"  :disabled="!editInfo"
              type="primary" @click="saveFirm" v-if="editInfo">保存数据</el-button>
            </el-form-item>
        </el-form>
        <!-- baseinfo-end -->
    </el-tab-pane>
    <el-tab-pane label="Logo" name="second">
      <!-- logoImg -->
      <div class="demo-image__placeholder">
        <div class="block">
          <el-image class="img" :src="firm.logo">
          </el-image>
        </div>
      </div>
        <!-- others this.API.apiUrl-->
        <el-upload
          :on-success="uploadSuccess"
          :on-error="uploadFail"
          :data="jsonData"
          class="upload-demo"
          ref="upload"
          :action="actionUrl"
          :file-list="fileList"
          :limit="1"
          :auto-upload="false">
        <el-button slot="trigger" size="small" v-if="editInfo">选取图片</el-button>
        <el-button style="margin-left: 10px;" v-if="editInfo"
        size="small" type="primary" @click="submitUpload">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
        <!-- others-end -->
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
  export default {
    data() {
      return {
        editInfo:checkRules(15),//系统信息编辑权限
        header:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        loading:true,
        activeName: 'first',
        firm:{
            name:''
        },
        jsonData:{
          method:'uploadImg'
        },
        fileList:[],
        actionUrl:this.API.api
      };
    },
    created(){
      this.getFirm()
      this.user = JSON.parse(sessionStorage.getItem('user'))
      this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods: {
      uploadFail(err){
       this.$message({
            message: err.msg?err.msg:err,
            type: 'warning'
          });
      },
      uploadSuccess(response){
        console.log(this.actionUrl)
        console.log(response)
        if(response.status==0){
          //suc
          this.firm.logo = this.API.api+response.data 
          this.saveFirm()
        }else{
          this.$message({
            message:response.msg,
            type: 'warning'
          });
        }
      },
      submitUpload() {
        this.$refs.upload.submit();
      },handlePreview(file) {
        console.log(file);
      }
      ,saveFirm(){
        //保存数据
        let that=this,params=this.firm 
        params.method="updateFirm",this.loading=true
        params.loading=true
        //console.log(this.activeName)
        //console.log(params)
        this.$ajax.post(this.API.api,params).then(res=>{
          //todo
          console.log(res)
          that.loading=false
          this.$message({
            message: res.msg,
            type: 'success'
          });
        }).catch(err=>{
          //err 
          console.log(err)
          that.loading=false
          this.$message({
            message: err.msg?err.msg:err,
            type: 'warning'
          });
        })
      }
      ,getFirm(){
        let that=this,params = {
          method:'getFirm',
        }
        this.$ajax.post(this.API.api,params).then(res=>{
          //TODO
          console.log(res)
          that.firm = res.data[0]
          that.loading = false
        }).catch(err=>{
          //Err
          console.log(err)
          this.loading = false
          this.$message({
            message: err.msg?err.msg:err,
            type: 'warning'
          });
        })
      }
    }
  };
</script>
<style lang="less">
.img{
  width:200px;
  height:150px;
  margin:10px 0;
  background-color: #dbdbdb;
}
</style>