/**
 * 存放项目接口地址
 */
const apiHost = '/admin/'///admin/
const apiWX = '/weixin/' 
const AccountsApis = {
    api:apiHost,
    codeUrl:apiWX,
    url:'https://admin.yhipm.cn/',
    apiUrl:'https://admin.yhipm.cn/admin/',//解决ueditor实际环境中上传图片不显示问题
    imgUrl:'https://yhipm.cn/request/upload/images/',
    code:"https://api.yhipm.cn/weixin/",
    //loginUrl: 'admin/',
    logoutUrl: '',
    cosUrl:"https://yh-1255992969.cos.ap-guangzhou.myqcloud.com/",//对象储存访问域名
}

export {
    AccountsApis
}