<template>
  <div id="app">
    <!-- 显示组件内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
@import "./assets/style/common.less";
</style>
