import axios from 'axios'
import qs from 'qs'
import { Loading } from 'element-ui'
import router from '../router'
let loadingInstance;
export const ajax = axios.create({
    headers: {
        source: 'h5',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
ajax.defaults.timeout = 15000

ajax.interceptors.request.use(function (config){
    //console.log(config)
    // 在发送请求前做些什么
    if(config.data&&config.data.Loading){
        loadingInstance = Loading.service();
    }
    if(sessionStorage.getItem('user')&&config.data&&!config.data.hasToken){
        //加入登陆信息
        let user = JSON.parse(sessionStorage.getItem('user'))
        //console.log(user)
        config.url += "?token="+user.token
    }
    config.data = qs.stringify(config.data)
    //console.log(config.url)
    return config
},function (err){
    console.log(err)
    return Promise.reject(err)
})

ajax.interceptors.response.use(function (response){
    //对响应数据做些什么
    //console.log(response)
    if(loadingInstance){
        loadingInstance.close();
    }
    let data = response.data 
    //console.log(data.status,"---")
    if(data.status==0){
        //success
        return Promise.resolve(data)
    }else if(data.status==8){
        //登陆失效
        sessionStorage.clear()
        router.replace({
            path: '/login',
            params:{
                type:"outlogin"
            }
        })
        return Promise.reject(data)
        //location.reload()
    }else{
        //fail
        return Promise.reject(data)
    }
},function(err){
    if(loadingInstance){
        loadingInstance.close();
    }
    return Promise.reject(err)
})
