//获取课程标签样式
function getCourseType(tag){
    let str = ''
    if(tag.indexOf("初级")!=-1){
        str = "success"
    }else if(tag.indexOf("进阶")!=-1){
        str = "warning"
    }else{
        str = "danger"
    }
    return str
}

/**
 * 转换图片的地址，后期移除
 * @param {*} url 
 * @param {*} api 
 * @returns 
 */
function resetImgUrl(url,api){
    let ar = url.split("/")
    return api+ar[ar.length-1]
}


/**
 * 过滤器
 * 
 */

let Base64 = require('js-base64').Base64
function jsEncode(str,key){
    key==null||key==''?key='yhpco':''
    let data=Base64.encode(str),keyArr=key.split("")
    let arr=data.split(""),length=arr.length;
    keyArr.forEach(function(item,index){
        if(index<length){
            arr[index]+=item
        }
    })
    let mstr=arr.join("").replace(/=/g,'O0O0O').replace(/\+/g,'o000o').replace(/\//g,'oo00o')
    return mstr
}
function jsDecode(str,key){
    //if(str.length<=1){return ""}
    if(str==''){
        return ''
    }
    try {
        key==null||key==''?key='yhpco':''
        str=str.replace(/O0O0O/g,'=').replace(/o000o/g,'+').replace(/oo00o/g,'/')
        //拆分字符串
        let a, b,aIndex = 0,bIndex = 1,arr = [],l=str.split("").length;
        str.split("").forEach((str, index) => {
            if (index % 2 === 0) {
                a = str;
                aIndex += 1 

            } else {
                b = str
                bIndex += 1
            }
            //console.log(index,bIndex-aIndex,a,b)
            if (a&&b&&(bIndex-aIndex === 1)) {
                arr.push(a+b)
            }else if(index==(l-1)&&(bIndex-aIndex === 0)){//奇数位
                arr.push(str)
            }
        });
        let keyArr=key.split("")
        let length = arr.length
        //console.log(arr)
        //return arr;
        keyArr.forEach(function(item,index){
            if(index<=length&&arr[index]&&arr[index][1]===item){
                arr[index]=arr[index][0]
            }
        })
        return Base64.decode(arr.join(""))
    } catch (error) {
        console.log(error)
        return ''
    }
    
}

/**
 * 进入库状态转换
 * @param {*} status 
 */
function EnxStatus(status){
    status = parseInt(status)
    let str = '未审核'
    switch(status){
        case 1:
            str = '审核未签名';
            break;
        case 2:
            str = '已完成';
            break;
        case 3:
            str = '已完成';
            break;
        case 4:
            str = '拒绝';
            break;
        default:
            str='制单未签名';
            break;
    }
    return str
}
/**
 * 替换空字符
 * @param {*} str 
 * @param {*} replace 
 * @returns 
 */
function name(str,replace){
    replace==null?replace="空":""
    return str==""?replace:str
}

/**
 * task/Scheduling.vue
 * 获取时间中的日期,2021-07-09 12:00=>2021-07-09
 * @param {*} time 
 * @returns 
 */
function dateFormat(time){
   return time.split(" ")[0]
}

/**
 * 获取日期中的天
 * @param {*} date 
 * @returns 
 */
function monthFormat(date){
    return date.split('-')[2]
}

/**
 * 开始时间至结束时间
 * @param {*} start 
 * @param {*} end 
 */
function timeRangeFormat(start,end){
   let sTimeArr = start.split(" ")[1].split(":")
   let eTimeArr = end.split(" ")[1].split(":")
   if(end.split(" ")[0]=="0000-00-00"){//无上传
    return sTimeArr[0]+":"+sTimeArr[1]+"至 --  "
   }
   if(start.split(" ")[0] == end.split(" ")[0]){//同一天
        return sTimeArr[0]+":"+sTimeArr[1]+"至"+eTimeArr[0]+":"+eTimeArr[1]
   }else{
        return sTimeArr[0]+":"+sTimeArr[1]+"至 "+end.split(" ")[0].split("-")[2]+"日"
        + eTimeArr[0]+":"+eTimeArr[1]
   }
}
/**
 * 获取状态tag的class
 * @param {*} index 
 */
function matchStatus(index){
    let status = '';
    switch (index*1){
        case 1:
            status = "info";
            break;
        case 2:
            status = "warning";
            break;
        case 3:
            status = "success";
            break;
        case 4:
            status = "danger";
            break;
        case 5:
            status = "success";
            break;
        default:
            status = "info";
            break;
    }
    return status;
}

/**
 * 转换预约处理状态
 * @param {*} index 
 * @returns 
 */
function matchAppoint(index){
    let status = '';
    switch (index*1){
        case 0:
            status = "待处理";
            break;
        case 1:
            status = "已接收";
            break;
        case 2:
            status = "已通过";
            break;
        case 3:
            status = "未通过";
            break;
        default:
            status = "未通过";
            break;
    }
    return status;
}

/**
 * 任务状态转换
 * @param {*} index 
 * @returns 
 */
function statusFormat(index){
    let status = '';
    switch (index*1){
        case 0:
            status = "已接收";
            break;
        case 1:
            status = "未接收";
            break;
        case 2:
            status = "进行中";
            break;
        case 3:
            status = "已完成";
            break;
        case 4:
            status = "放弃";
            break;
        case 5:
            status = "完成上传";
            break;
    }
    return status;
}

//获取节的中文描述
function getSection(id,sections){
    let section = ''
    for(var i=0;i<sections.length;i++){
        //console.log(id,sections[i].cid)
        if(sections[i].cid==id){
            section = sections[i].label
            break
        }
    }
    return section
}
//获取章的中文描述
function getChapter(id,chapters){
    let chapter = ''
    for(var i=0;i<chapters.length;i++){
        //console.log(id,chapters[i].cid)
        if(chapters[i].nid==id){
            chapter = chapters[i].label
            break
        }
    }
    return chapter
}
//获取专题的中文描述
function getDocName(id,docList){
    let chapter = ''
    for(var i=0;i<docList.length;i++){
        if(docList[i].value==id){
            chapter = docList[i].label
            break
        }
    }
    return chapter
}
export {
    getCourseType,//获取课程tag样式
    resetImgUrl,//图片地址转换
    EnxStatus,//进出库状态
    matchAppoint,//转换预约状态
    getDocName,
    getChapter,
    getSection,
    name,
    dateFormat,
    statusFormat,
    timeRangeFormat,
    monthFormat,
    matchStatus,
    jsDecode,
    jsEncode
}