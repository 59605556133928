<template>
    <div class="box-main" :style="{height:windowHeight+'px'}">
        <div class="login-box">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="公司名">
                    <el-input v-model="form.firm" name="firm" placeholder="请输入公司名"></el-input>
                </el-form-item>
                <el-form-item label="用户名">
                    <el-input v-model="form.username" name="username" type="text" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="用户密码">
                    <el-input v-model="form.password" name="password" type="password" placeholder="请输入用户密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-loading.fullscreen.lock="loading" type="primary" style="width:80%" @click="onSubmit">登陆</el-button>
                </el-form-item>
            </el-form>
        </div>
      <div class="bgd" :style="{height:windowHeight+'px'}">
      </div>
      <div class="footer">
        {{company+' v'+version}}
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
         target="_blank" rel="noopener noreferrer">
        {{Filing_no}}</a>
      </div>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
  export default {
    data() {
      return {
        loading:false,
        windowHeight:window.innerHeight,
        form: {
            firm:'广州公司',
            username: '',
            password:'',
            platform:this.CONST.APP_PLATFORM,
            version:this.CONST.APP_VERSION
        },
        version:this.CONST.APP_VERSION,
        company:this.CONST.company,
        Filing_no:this.CONST.Filing_no
      }
    },
    created(){
      if(this.$route.params.type&&this.$route.params.type=="outlogin"){
        this.$message({
            message: '登陆状态已过期！请重新登陆',
            type: 'warning'
          });
      }
    },
    methods: {
      onSubmit() {
        //清空之前的数据
        sessionStorage.clear()
        localStorage.clear()
        this.form.method='login'
        //this.form.version = this.CONST.APP_VERSION
        if(this.form.username.length<=1||this.form.password.length<=5
        ||this.form.firm==''){
          this.$message({
            message: this.CONST.FAIL_LOGIN_PARAMS,
            type: 'warning'
          });
          return
        }
        this.loading=true
        this.$ajax.post(this.API.api,this.form).then(res=>{
            this.loading=false
            this.form.password=''
            sessionStorage.setItem('rules',res.data[0].rule);
            let hasRule = checkRules(4);
            //console.log(hasRule,'---')
            if(hasRule){
               //存储用户信息
              this.$store.dispatch('updateUser',{
                user:res.data[0]
              })
              sessionStorage.setItem('user',JSON.stringify(res.data[0]))
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.$router.push({path:'/Home'})
            }else{
              //没有权限
              this.$message({
                message: '无登陆权限！',
                type: 'warning'
              });
            }
            
        }).catch(err=>{
          //console.log(err)
          this.loading=false
          this.form.password=''
          this.$message({
            message: err.msg?err.msg:'登陆失败！',
            type: 'warning'
          });
        })
        //this.$router.push({path:'/Home'})
      }
    }
  }
</script>
<style lang="less" scoped>
body{
  background: #f8f8f8;
}
.box-main{
    position: relative;
    display: inline-block;
    width:100%;
    height:100%;
    padding:0px;
    .bgd{
      width:100%;
      height:80%;
      position:fixed;
      background: url("../assets/images/bgd.jpeg") no-repeat ;
      background-size:cover;
      background-position: -2px -100px;
      z-index: -1;
      top:0;
      left:0;
    }
    .login-box{
        width:360px;
        z-index: 10;
        margin:200px auto;
        border-radius: 10px;
        padding:10px 20px;
        padding-top:50px;
        background: rgba(255, 255, 255, 0.7);
    }
    .el-input__inner{
      background: rgba(255, 255, 255, 0.8);
    }
    .el-button.el-button--primary{
      background: rgba(2, 129, 129,0.8);
    }
    .footer{
      position:fixed;
      bottom:0;
      line-height: 50px;
      color:#999;
      font-size: 0.8em;
      text-align: center;
      width:100%;
      a{
        color:#999;
        text-decoration: underline;
      }
    }
}
</style>