<template>
<!-- 首页顶部菜单栏 -->
 <div class="header">      
    <div class="left">
    <i :class="showLeft?'el-icon-s-fold':'el-icon-s-unfold'" class="left-icon" @click="showMenu"></i>
    </div>
    <div class="right">
    <el-dropdown @command="select">
      <el-avatar class="avatar" :size="40" :src="avatarUrl"></el-avatar>
        <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="0">退出</el-dropdown-item>
        <el-dropdown-item command="1">设置</el-dropdown-item>
        <el-dropdown-item command="2">主题</el-dropdown-item>
      </el-dropdown-menu>          
    </el-dropdown>
    <div class="amdinText">{{user.username}}</div>
    </div>           
</div>
            
</template>
<script>
export default {
    props:[],
    data(){
        return {
            showLeft:true,
            avatarUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            
        }
    },
    created(){
    },
    methods: {
        showMenu(){
            this.showLeft?this.showLeft=false:this.showLeft=true 
            this.$emit("showMenu",this.showLeft)
        },
        select(e){
          let that = this
          switch(parseInt(e)){
            case 0:
              //退出
              this.$emit("logout")
              break;
            case 1:
              //设置
              break;
            case 2:
              //设置主题
              that.$emit("setTheme")
              break;
          }
        }
    },
    computed: {
      user(){
        //console.log("user",this.$store.state.user)
        return this.$store.state.user
      }
    }
}
</script>
<style lang="less">
  .header{
    display: flex;
    font-size: 12px;
    .left{
      flex:10;
      .left-icon{
          cursor:pointer;
          font-size: 20px;
      }
    }
    .right{
      display: flex;
      flex:14;
      justify-content: flex-end;
      text-align: right;
      height:60px;
      line-height: 60px;
    }
    .avatar{
      margin:10px auto;
    }
    .amdinText{
      height: 60px;
      line-height: 60px;
      margin:0 5px;
      text-align: center;
    }
  }
</style>
