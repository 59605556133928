import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PPT_ from '../views/PPT_.vue'
//import About from '../views/About.vue'
import Firm from '../views/Firm/Firm'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/Login'
  },{
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      title:'后台管理',
      type:'login'
    },
    redirect:'/Firm/Firm',
    children:[
      {
        path: '/Firm/Firm',
        name: 'Firm',
        component: Firm,
        meta: {
          title:'企业信息',
          type:'login',
          keepAlive:true
        }
      },
      {
        path: '/Firm/Cert',
        name: 'Cert',
        meta: {
          title:'资质证件',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Firm/Cert')
      },
      {
        path: '/Task/Scheduling',
        name: 'Scheduling',
        meta: {
          title:'工单列表',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Task/Scheduling')
      },
      {
        path: '/Task/Plan',
        name: 'TaskPlan',
        meta: {
          title:'计划',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Task/Plan')
      },
      {
        path: '/Task/Calendar',
        name: 'Calendar',
        meta: {
          title:'工单日历',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Task/Calendar')
      },
      {
        path: '/Task/Project',
        name: 'Project',
        meta: {
          title:'服务项目',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Task/Project')
      },
      {
        path: '/Task/Report',
        name: 'Report',
        meta: {
          title:'报告详情',
          type:'login',
          //keepAlive:true
        },
        component: ()=>import('@/views/Task/Report')
      },
      {
        path: '/Task/Mold',
        name: 'Mold',
        meta: {
          title:'工单标签',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Task/Mold')
      },{
        path: '/Form/addTest',
        name: 'add_test',
        meta: {
          title:'辅助统计',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Form/addTest')
      },
      {
        path: '/Form/add',
        name: 'form_add',
        meta: {
          title:'添加表单',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Form/add')
      },
      {
        path: '/Form/list',
        name: 'form_list',
        meta: {
          title:'表单列表',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Form/list')
      },
      {
        path: '/Client/Client',
        name: 'Client',
        meta: {
          title:'客户列表',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Client/Client')
      },
      {
        path: '/Client/Device',
        name: 'Device',
        meta: {
          title:'客户设备',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Client/Device')
      },
      {
        path: '/Client/DeviceType',
        name: 'DeviceType',
        meta: {
          title:'设备类型',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Client/DeviceType')
      },{
        path: '/Client/WorkOrder',
        name: 'WorkOrder',
        meta: {
          title:'客户工单',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Client/WorkOrder')
      },{
        path: '/Client/Appointment',
        name: 'Appointment',
        meta: {
          title:'客户预约',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Client/Appointment')
      },{
        path: '/Staff/Staff',
        name: 'Staff',
        meta: {
          title:'员工列表',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Staff/Staff')
      },{
        path: '/Staff/Department',
        name: 'Department',
        meta: {
          title:'部门设置',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Staff/Department')
      },{
        path: '/Warehouse/stockCheck',
        name: 'stockCheck',
        meta: {
          title:'库存盘点',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/stockCheck')
      },{
        path: '/Warehouse/Warehouse',
        name: 'Warehouse',
        meta: {
          title:'仓库管理',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/Warehouse')
      },{
        path: '/Warehouse/Goods',
        name: 'Goods',
        meta: {
          title:'物料管理',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/Goods')
      },{
        path: '/Warehouse/stockList',
        name: 'GoodsStock',
        meta: {
          title:'库存',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/stockList')
      },{
        path: '/Warehouse/EN',
        name: 'EN',
        meta: {
          title:'入库单',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/EN')
      },{
        path: '/Warehouse/EX',
        name: 'EX',
        meta: {
          title:'出库单',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/EX')
      },{
        path: '/Warehouse/related_party',
        name: 'related_party',
        meta: {
          title:'往来单位',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/related_party')
      },{
        path: '/Warehouse/usage',
        name: 'usage',
        meta: {
          title:'物料使用记录',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Warehouse/usage')
      },{
        path: '/Document/Editor',
        name: 'Editor',
        meta: {
          title:'编辑器',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Document/Editor')
      },{
        path: '/Document/List',
        name: 'List',
        meta: {
          title:'专栏文章',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Document/List')
      },{
        path: '/Class/Class',
        name: 'Class',
        meta: {
          title:'学院',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Class/Class')
      },{
        path: '/Class/Test',
        name: 'Test',
        meta: {
          title:'测试题',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Class/Test')
      },{
        path: '/Class/Other',
        name: 'Other',
        meta: {
          title:'其它设置',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Class/Other')
      },{
        path: '/Test/index',
        name: 'Testindex',
        meta: {
          title:'考评设置',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Test/index')
      },{
        path: '/Test/test',
        name: 'Testtest',
        meta: {
          title:'考评',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Test/test')
      },{
        path: '/Doc/Doc',
        name: 'CicDoc',
        meta: {
          title:'文件管理',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Doc/Doc')
      },{
        path: '/Doc/DocList',
        name: 'CicDocList',
        meta: {
          title:'腾讯云文件',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views//Doc/DocList')
      }
    ]
  },{
    path: '/PPT_',
    name: 'PPT_',
    component: PPT_,
    meta: {
      title:'后台管理',
      type:'login'
    },
    redirect:'/Test/ppt',
    children:[
      {
        path: '/Test/ppt/:id/',
        name: 'testppt',
        meta: {
          title:'ppt',
          type:'login',
          keepAlive:true
        },
        component: ()=>import('@/views/Test/ppt')
      }
    ]
  },{
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      title:'登陆'
    },
  },{
    path: '/Excel/index',
    name: 'Excel',
    component: ()=>import('@/views/Excel/index'),
    meta: {
      title:'Excel',
    },
  },{
    path: '/Document/Document/:nid/:id',
    name: 'Document',
    component: ()=>import('@/views/Document/Document'),
    meta: {
      title:'文档',
    },
  },{
    path: '/Report/show/:tid/',
    name: 'report_show',
    component: ()=>import('@/views/Report/show'),
    meta: {
      title:'报告',
    },
  }
  
]

const router = new VueRouter({
  mode:'history',//https://router.vuejs.org/zh/guide/essentials/history-mode.html
  routes
})

export default router
