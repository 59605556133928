<template>
    <div class="logo">
        <el-image :src="!Logo?this.CONST.LOG_URL:Logo" :lazy="true"
        :fit="iscollapse?'cover':'fill'"></el-image>
    </div>
</template>
<script>
export default {
    props:{
        Logo:{
            type:String,
            value:null
        },
        background:{
            type:String,
            value:'#ffffff'
        },
        iscollapse:Boolean
    },
    data(){
        return {

        }
    },
    created(){
    },
    methods:{

    }
}
</script>
<style lang="less">
.logo{
    height:60px;
    line-height:60px;
    background: none;
    padding:10px 20px;
}
</style>