import Vue from 'vue'
import Vuex from 'vuex'
//import {decrypt} from '@/utils/util.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:JSON.parse(sessionStorage.getItem("user"))||"",//从session中获取
  },
  mutations: {
    updateUserInfo(state,payload){
      this.state.user = payload
    }
    ,
    getUserInfo(){
      return this.state.user
    }
  },
  actions: {
    updateUser (context,payload){
      context.commit('updateUserInfo',payload)
    },
    getUser(context){
      context.commit('getUserInfo')
    }
  },
  modules: {
  },
  getters: {
    getUser: state=>{
      return state.user
    }
  }
})
