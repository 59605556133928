import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import * as filters from './utils/filters'
import CONST from './utils/constants'//常量
import {AccountsApis} from './utils/apis'
import './assets/style/theme.less'
import './assets/style/common.less'
import 'vant/lib/index.css'//vant样式
import { ajax } from '@/utils/ajax'
import elDragDialog from "@/utils/directive/el-dragDialog";
import Driver from "driver.js"
import "driver.js/dist/driver.min.css"
//reveal.js加载ppt
import "/node_modules/reveal.js/dist/reveal.css";
import "/node_modules/reveal.js/dist/theme/white.css";

Vue.config.productionTip = false
Vue.prototype.CONST = CONST
Vue.prototype.API = AccountsApis
Vue.prototype.$ajax = ajax

Vue.directive('el-drag-dialog', elDragDialog)
router.beforeEach((to, from, next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const type = to.meta.type
  // 判断该路由是否需要登录权限
  if (type === 'login') {
    if (sessionStorage.getItem('user')) {
      next()
    } else {
      localStorage.clear()
      alert(CONST.LOGIN_STATUS_OUT)
      next('/login')
      // if(to.fullPath==='/login'){
      //   next();
      // }else{
      //   next('/login')
      // }
    }
  } else {
    next()  // 确保一定要有next()被调用
  }
})

//注册过滤器
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.onresize = function () {
  //如果窗口编号弹出提示
  let URL = window.location.href
  console.log(URL.indexOf("Report/show/"));
  if(URL.indexOf("Report/show/")==-1&&document.documentElement.clientWidth<1000){
    alert('当前窗口高度可能导致页面显示不完整！')
  }
}
// reveal.js在线ppt

import Reveal from 'reveal.js';
Vue.use(Reveal)

//echart
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
console.log(echarts)
//reveal.js--end

//引导设置
Vue.prototype.$Driver = new Driver({
 
  classname:"scoped class",// 为你的指示模块添加类名 如果你要取消 某一个按钮（比如：“上一步””下一步“，”完成“）的 这个类名还是要加上的

  animate:true, //在更改突出显示的元素时设置动画

  opacity: 0.65,//背景不透明度（0表示只有弹出窗口，没有覆盖）

  padding:10,//元素与边缘的距离

  allowClose:true,//单击覆盖是否应关闭

  overlayClickNext:false,//单击overlay click是否转到下一步

  doneBtnText:'完成',//最后一个按钮上的文本

  closeBtnText:'关闭', //此步骤的关闭”按钮上的文本

  nextBtnText:'下一步',//此步骤的下一步按钮文本

  prevBtnText:'上一步',//此步骤的上一个按钮文本

  showButtons:true,//不在页脚中显示控件按钮

  onReset: (data) => {console.log(data)}, // 引导结束触发

  onNext: (data) => {console.log(data)},// 引导进入下一步触发

})


