import CryptoJS from 'crypto-js'
import CST from 'constants'
import {accAdd,accSub,accMul,accDiv} from '@/utils/caculate.js' 
const key = CryptoJS.enc.Utf8.parse(CST.KEYS);  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse(CST.KEYS);   //十六位十六进制数作为密钥偏移量

//转换规格单位
export function ConvertUnits(en_list,ex_list,number,c_unit,specification){
  console.log(en_list,ex_list)
  //计算入库数据
  let en=0,ex=0,unit=''
  //console.log(Array.isArray(en_list))
  if(en_list.length>=1&&Array.isArray(en_list)){
    en_list.forEach(item=>{
      en=accAdd(item.specification_number*1,en)
      console.log(en,'en')
      unit=item.unit
    })
  }
  //计算出库数据
  if(ex_list.length>=1&&Array.isArray(ex_list)){
    ex_list.forEach(item=>{
      console.log(item)
      ex=accAdd(item.specification_number*1,ex)
      unit=item.unit
      console.log(ex,'ex')
    })
  }
  //计算
  let sub = accSub(ex,en)
  let data = ConverSur(specification,number,c_unit,sub,unit)
  //console.log("上次剩下"+number+c_unit,"出入库总和："+sub+unit,"合计："+data.number+c_unit)
  return {surplus:data.number,unit:data.unit}
}

//转换物料单位，成本
export function ConverSur(specification,num1,num1_unit,num2,num2_unit){
  //console.log(specification,num1,num1_unit,num2,num2_unit)
  let str = specification.split("/"),index1,index2
  if(num1_unit!=num2_unit){
    //两个单位不相等
    let datalist=[]
    str.forEach((item,i)=>{
      let arr = item.split("*")
      //console.log(arr[1])
      if(arr[1]==num1_unit){
        index1 = i
        //console.log(arr[1],'---')
      }else if(arr[1]==num2_unit){
        index2 = i
      }
      datalist.push({
        scale:arr[0],
        unit:arr[1]
      })
    })
    let scale = 1,turn=0
    //分类情况,盘点单位小于出入库单位，如出库1箱子，盘点40g
    //console.log(index1,index2,'哪个大')
    if(index1>index2){
      for(let i=(index2+1);i<=index1;i++){
        //console.log(i,scale,datalist[i].scale,'scale index1<index2')
        scale=accMul(scale,datalist[i].scale*1)
      }
      //console.log(scale,'scale')
      turn = accMul(scale,num2)
    }else if(index1<index2){
      for(let i=(index1+1);i<=index2;i++){
        //console.log(i,scale,datalist[i].scale,'scale,index1<index2')
        scale=accMul(scale,datalist[i].scale*1)
      }
      //console.log(num2,scale,'num2,scael')
      turn = accDiv(num2,scale)
    }
    //console.log(turn,'turn')
    return{
      number:accAdd(turn,num1),
      unit:num1_unit
    }
    
  }else{
    //两者单位想等则直接相加
    return{
      number:accAdd(num1,num2),
      unit:num1_unit
    }
  }
}

//计算时间差
export function GetDateDiff(startTime, endTime, diffType) {
  //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式 
  startTime = startTime.replace(/-/g, "/");
  endTime = endTime.replace(/-/g, "/");
  //将计算间隔类性字符转换为小写
  diffType = diffType.toLowerCase();
  var sTime =new Date(startTime); //开始时间
  var eTime =new Date(endTime); //结束时间
  //作为除数的数字
  var timeType =1;
  switch (diffType) {
      case"second":
          timeType =1000;
      break;
      case"minute":
          timeType =1000*60;
      break;
      case"hour":
          timeType =1000*3600;
      break;
      case"day":
          timeType =1000*3600*24;
      break;
      default:
      break;
  }
  return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(timeType));
}

/**
 * js获取前一天日期
 * @param {*} date 
 * @param {*} day 
 * @returns 
 */
export function getNextDate(date, day) { 
  var dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
}

/**
 * 转译html字符
 * @param {*} html 
 * @returns 
 */
export function HTMLEncode(html) {
  var temp = document.createElement("div");
  (temp.textContent != null) ? (temp.textContent = html) : (temp.innerText = html);
  var output = temp.innerHTML;
  temp = null;
  output= output.replace(/&/g, "&amp;"); 
  output = output.replace(/</g, "&lt;"); 
  output = output.replace(/>/g, "&gt;"); 
  output = output.replace(/ /g, "&nbsp;"); 
  output = output.replace(/\\/g, "&#39;"); 
  output = output.replace(/"/g, "&quot;"); 
  output = output.replace(/\n/g, "<br/>"); 
  return output;
 }

/**
 * 反转译html字符
 * @param {*} text 
 * @returns 
 */
export function HTMLDecode(text) { 
  var temp = document.createElement("div"); 
  temp.innerHTML = text; 
  var output = temp.innerText || temp.textContent; 
  temp = null; 
  output = output.replace(/&amp;/g, "&"); 
  output = output.replace(/&lt;/g, "<"); 
  output = output.replace(/&gt;/g, ">"); 
  output = output.replace(/&nbsp;/g, " "); 
  output = output.replace(/&#39;/g, "\\");
  output = output.replace(/&quot;/g, "\" "); 
  output = output.replace(/<br\/>/g, "\n"); 
  return output; 
 }

/**
 * 查找是否获取权限
 * @param {*} num 
 */
export function checkRules(num){
  let rules = sessionStorage.getItem("rules")
  if(rules&&rules!=''){
    try {
      let ar = JSON.parse(rules)
     return ar.includes(num.toString())||ar.includes("1")
    } catch (e) {
      //console.log(e)
      return false
    }
  }else{
    return false
  }
  //num.includes(num)
}

//检查权限，传入权限
export function hasRules(num,rules){
  //let rules = sessionStorage.getItem("rules")
  if(isNaN(num)){
    num = '"'+num+'"'
  }
  //console.log(num)
  if(rules&&rules!=''){
    try {
      let ar = JSON.parse(rules)
      //console.log(num,ar,ar.includes(num),ar.includes(1))
     return ar.includes(num)||ar.includes("1")
    } catch (e) {
      //console.log(e)
      return false
    }
  }else{
    return false
  }
  //num.includes(num)
}

//根据某个json字段分组
export function resetJsonBy(array,key,asKey){
  !asKey?asKey=key:''
  let json={},arr = []
  array.forEach(item=>{
   //console.log(item[key])
   if(!json[item[key]]){
    let m = {
     data:[]
    }
    m[asKey]=item[key]
    m.data.push(item)
    arr.push(m)
    //console.log(m)
    json[item[key]]=arr
   }else {
    //console.log(arr)
    arr.forEach((it,index)=>{
     if(it[asKey]==item[key]){
      //console.log(it)
      arr[index].data.push(item)
     }
    })
   }
  })
  return arr.sort(objSort(key))
 }

 // 按照某个字段排序
function objSort(prop) {
  return (obj1, obj2) => {
   let val1 = obj1[prop];
   let val2 = obj2[prop];
   // if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
   //  val1 = Number(val1);
   //  val2 = Number(val2);
   // }
   
   if(val1==='#'){
    //console.log(val1,val2)
    return 1
   }else if(val1==='#'){
    //console.log(val1,val2)
    return -1
   }
   if (val1 < val2) {
    return -1;
   } else if (val1 > val2) {
    return 1;
   } else {
    return 0;
   }
  }
 }
/**
 * 重新按照某个字段排练
 * @param {*} arr 
 * @param {*} item 
 */
export function regroupByItem(arr){
  var newArr = [];
	arr.forEach(function (oldData) {
    var index = -1;
    var alreadyExists = newArr.some(function (newData, j) {
        if (oldData.date.substring(0, 10) === newData.date.substring(
                0, 10)) {
            index = j;
            return true;
        }
    });
		if (!alreadyExists) {
      var res=[];
      res.push(oldData);
          newArr.push({
            date: oldData.date,
            res: res
          });
      } else {
          newArr[index].res.push(oldData);
      }
    });
		return newArr;
}
/**
 * 获取某月的天数
 * @param {*} year 
 * @param {*} month 
 * @returns 
 */
export function getMonthDay(month,year) {
  !year?year = new Date().getFullYear:''
  return new Date(year, month + 1, 0).getDate()
}
/**
 * 获取/转换时间
 * @param {*} date 
 * @returns 
 */
export function formatTime (date){
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate() //<10?'0'+date.getDate():date.getDate()
  const hour = date.getHours() //<10?'0'+date.getHours():date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return `${[year, month, day].map(formatNumber).join('-')} ${[hour, minute, second].map(formatNumber).join(':')}`
}
function formatNumber(n){
  n = n.toString()
  return n[1] ? n : `0${n}`
}

/**
 * 加密解密
 * @param {*} word 
 * @returns 
 */
export function encrypt(word){
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.ciphertext.toString().toUpperCase();
}
export function decrypt(word){
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}
export default {
    //加密
    
}