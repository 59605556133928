<template>
    <div class="box">
        <div class="box-item" :id="index" @click="select" v-for="(item,index) in themeList" v-bind:key="index">
           <el-image
            :id="index"
                style="width: 100px; height: 100px"
                :src="item.url"
                fit="fill"></el-image>
            <div class="title">{{item.title}}</div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            themeList:[
                {
                    url:require("../../assets/images/theme-day.png"),
                    title:"白天模式",
                    className:"dayTheme"
                },{
                    url:require("../../assets/images/theme-night.png"),
                    title:"暗黑模式",
                    className:"darkTheme"
                }
            ]
        }
    },
    methods:{
        select(e){
            console.log(e)
            let index= e.target.id
            this.$emit("select",this.themeList[index].className)
        }
    }
}
</script>
<style lang="less">
.box{
    display: flex;
}
.box-item{
    padding:10px 10px;
    cursor: pointer;
}
.box-item:hover{
    background: #dbdbdb;
}
.title{
    width:100%;
    text-align: center;
    line-height: 40px;
    font-size: 1.2em;
    font-weight: 450;
}
</style>