/**
 * 常量配置
 */
const company = '粤洪服务';
const Filing_no = '粤ICP备16050675号'
const APP_VERSION = '1.2.1';
const APP_PLATFORM = 'web';
const LOG_URL = 'https://yhipm.cn/assets/images/logo.png'
const Bucket = 'yh-1255992969';//储存桶名称
const Region = 'ap-guangzhou';//储存桶地域
//KEYS加密解密
const KEYS = 'yh00000yh'

//fail err const
const NO_RESULTS = "获取数据失败！"
const NO_RULES = "你的权限不足！"
const NEED_RULES = "请选择账号权限！"
const FAIL_TIME_OUT = "请检查网络！"
const FAIL_LOGIN_PARAMS = "请输入必填内容！"
const LOGIN_STATUS_OUT = "请先登陆！"
const DEL_CONFIRM = "确认要删除该条数据吗？"
const DEL_CANCEL = "你已取消该操作"
const DEL_FAILD = "删除失败！"
const DEL_SUC = "删除成功！"
const INPUT_HINT_NAME = "请输入名称"
const INPUT_HINT_PASSWORD = "请输入密码"
const INPUT_PASS_TWICE = "请再次输入密码"
const INPUT_PASS_DIFFERENT = "两次密码输入不一致"
const INPUT_HINT_USERNAME = "请输入员工姓名"
const INPUT_HINT_LEVEL = "请输入职位"
const LENGTH_2_5 = '长度在 2 到 5 个字符'
const LENGTH_6_10 = "长度在6到10个字符"
const LENGTH_2_20 = "长度在2-20个字符"
const INPUT_HINT_DEPARTMENT = "请选择部门"
const INPUT_HINT_USERID = "请输入员工编号"
const SAERCH_REQUIRED_SELECT = "请选择搜索类型"
const INPUT_REQUIRED_TIME = "请选择时间区间"
const REQUIRED_PROJECT = "请选择服务项目"
const REPEAT_COMMIT = "请勿重复提交"
const REQUIRED_RULES = "请设置账号权限"
const REQUIRED_CHAPTER = "选择专栏后需要选择章节"
const REQUIRED_CONTENT = "请输入内容"
const CLIENT_NOT_EXIT = "客户ID不存在，可能会导致添加失败"

//success const
const SUC_ADD = "添加成功！"

//hint
const NOT_ORG = '抱歉，你不是制单人！'
const NOT_ALLOWED_SAME = '审核人不能为制单人！'
const USERID = "员工编号"
const ONLY_REGION = "已进行区域筛选"
const PLACEHOLDER = "请输入关键字"
const ADD_CERT = "添加资质"
const ADD_CERT_NAME = "资质名称"
const ADD_CERT_IMG = "资质图片"
const USERNAME = "员工姓名"
const PASSWORD = "账户密码"
const PASS_CONFIRM = "确认密码"
const CONTACT = "联系电话"
const LEVEL = "职位"
const DEPART = "部门"
const HIREDATE = "入职日期"
const ACCOUNT_RULES = "权限设置"
const ADD_ACCOUNT = "添加账号"
const MODIFY_ACCOUNT = "修改账号"
const ADD_CHAPTER_BTN = "添加专栏"
export default {
    Region,
    Bucket,
    company,
    Filing_no,
    NOT_ALLOWED_SAME,
    NOT_ORG,
    NO_RESULTS,
    INPUT_HINT_USERID,
    USERID,
    NO_RULES,
    NEED_RULES,
    CLIENT_NOT_EXIT,
    ONLY_REGION,
    REQUIRED_CONTENT,
    REQUIRED_CHAPTER,
    LENGTH_2_20,
    ADD_CHAPTER_BTN,
    PLACEHOLDER,
    REQUIRED_RULES,
    ADD_ACCOUNT,
    MODIFY_ACCOUNT,
    ACCOUNT_RULES,
    HIREDATE,
    DEPART,
    LEVEL,
    CONTACT,
    USERNAME,
    PASSWORD,
    PASS_CONFIRM,
    INPUT_PASS_TWICE,
    INPUT_HINT_PASSWORD,
    INPUT_PASS_DIFFERENT,
    SUC_ADD,
    REPEAT_COMMIT,
    APP_VERSION,
    APP_PLATFORM,
    LOG_URL,
    FAIL_LOGIN_PARAMS,
    KEYS,
    LOGIN_STATUS_OUT,
    DEL_CONFIRM,
    DEL_FAILD,
    DEL_CANCEL,
    DEL_SUC,
    INPUT_HINT_NAME,
    ADD_CERT,
    ADD_CERT_NAME,
    ADD_CERT_IMG,
    SAERCH_REQUIRED_SELECT,
    INPUT_REQUIRED_TIME,
    FAIL_TIME_OUT,
    REQUIRED_PROJECT,
    INPUT_HINT_USERNAME,
    INPUT_HINT_DEPARTMENT,
    LENGTH_2_5,
    LENGTH_6_10,
    INPUT_HINT_LEVEL
}