<template>
    <el-container class="el-container" 
  :style="'height:'+winHeight+'px'">
  <router-view></router-view>
    </el-container>
</template>
<script>
export default {
    data(){
        return{
            winHeight:window.innerHeight
        }
    }
}
</script>