<template>
    <!--  -->
  <el-container class="el-container" 
  :style="'height:'+winHeight+'px'">
        <el-aside :width="asideWidth+'px'" >
          <Logo v-if="!iscollapse"/>
          <Tabbar :iscollapse="iscollapse"/>
        </el-aside>
        <el-container>
          <el-header>
          <Header v-on:showMenu="showMenu" @setTheme="setTheme" @logout="logout"/>
          </el-header>
          <el-main>
            <keep-alive>
           <router-view></router-view>
            </keep-alive>
          </el-main>
        </el-container>
        <!-- 添加资质 -->
        <el-dialog title="选择主题" 
        :visible.sync="drawer">
            <SelectTheme @select="select"/>
        </el-dialog>
  </el-container>
    <!--  -->
</template>

<script>
// @ is an alias to /src
//import { ajax } from '@/utils/ajax'
import Tabbar from '@/components/common/Tabbar'
import Header from '@/components/common/Header'
import Logo from '@/components/common/Logo'
import SelectTheme from '@/components/common/SelectTheme'
export default {
  name: 'Home',
  components: {
    SelectTheme,
    Tabbar,
    Header,
    Logo
  }
  ,data() {
      return {
        drawer:false,
        themeList:["dayTheme","darkTheme"],
        theme:"darkTheme",
        iscollapse:false,
        asideWidth:200,
        winHeight:window.innerHeight
      }
  }
  ,created(){
    let theme = localStorage.getItem("theme")
    //console.log(theme)
    if(theme&&theme!=''){
      this.theme = theme
    }
    this.changeTheme()
  }
  ,methods:{
    logout(){
      //退出登陆
      sessionStorage.clear()
      localStorage.clear()
       this.$router.push({path:'/login'})
    },
    //改变主题
    select(e){
      console.log(e)
      this.theme=e
      localStorage.setItem("theme",e)
      this.drawer = false
      this.changeTheme()
    },
    showMenu(e){
      if(e){
        this.asideWidth=200
        this.iscollapse=false
      }else{
        this.asideWidth=64
        this.iscollapse=true
      }
    },
    setTheme(){
      this.drawer = true
    },
    //改变主题("darkTheme","dayTheme")
    changeTheme(){
      //document.getElementsByTagName('body')[0].className="darkTheme"
      document.getElementsByTagName('body')[0].className=this.theme
    }
  }
}
</script>
<style lang="less">
  
</style>
